import React, { useState, useEffect } from 'react';
import '../styles/components/Header.css';
import { useMsal } from "../msal-context";
import { loginRequest } from "../Configs/auth-config";
import loginIcon from "../images/IconLogin.png"
import logoutIcon from "../images/IconLogout.png"
import Applicationselector from './Applicationselector.js'
import Languageselector from './Languageselector.js'
import Companyselector from './Companyselector.js'
import { isMobileAccess } from '../helpers/authorizationHelper'
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";

const Menu = (props) => {
    const { isAuthenticated, user, login, logout } = useMsal();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
    let userMenu = {};
    let userMenuBtn = {};
    let mainMenu = {};
    let mainMenuBtn = {};

    const [t, i18n] = useTranslation('common');

    const setUserMenuRef = (node) => {
        userMenu = node;
    }

    const setUserMenuBtnRef = (node) => {
        userMenuBtn = node;
    }

    const setMainMenuRef = (node) => {
        mainMenu = node;
    }

    const setMainMenuBtnRef = (node) => {
        mainMenuBtn = node;
    }

    const handleClickOutside = (e) => {
        handleClickOutsideUserMenu(e);
        handleClickOutsideMainMenu(e);
    }

    const handleClickOutsideMainMenu = (e) => {
        if (isMenuOpen && (mainMenu && !mainMenu.contains(e.target)) && (mainMenuBtn && !mainMenuBtn.contains(e.target))) {
            setIsMenuOpen(false);
        }
    }

    const handleClickOutsideUserMenu = (e) => {
        if (isUserMenuOpen && (userMenu && !userMenu.contains(e.target)) && (userMenuBtn && !userMenuBtn.contains(e.target))) {
            setIsUserMenuOpen(false);
        }
    }

    function toggleMenu() {
        setIsMenuOpen(!isMenuOpen);
    };

    function toggleUserMenu() {
        setIsUserMenuOpen(!isUserMenuOpen);
    };

    //component mount event
    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside, false);
    }, [handleClickOutside]);

    return (
        <header className="main-navigation">
            <nav className="navbar navbar-expand-lg">
                <Languageselector onError={props.onError} />
                <Link className="navbar-brand" to="/home">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" color="#000" stroke="none" className="main-navigation__image">
                        <path stroke="none" d="M28.7 5.3L24.4 1l-4 4.3L-.5 25.9l4.3 4.3L24.4 9.4l20.9 20.8 4.2-4.3z" className="st0"></path>
                        <path stroke="none" d="M7.3 30.6V49h11.2V34.7h12.3V49h11.1V30.6L24.6 13.8z" className="st0"></path>
                    </svg>
                </Link>
                {/*<a href="/home" className="navbar-brand">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" color="#000" stroke="none" className="main-navigation__image">
                        <path stroke="none" d="M28.7 5.3L24.4 1l-4 4.3L-.5 25.9l4.3 4.3L24.4 9.4l20.9 20.8 4.2-4.3z" className="st0"></path>
                        <path stroke="none" d="M7.3 30.6V49h11.2V34.7h12.3V49h11.1V30.6L24.6 13.8z" className="st0"></path>
                    </svg>
                </a>*/}
                <button className="navbar-toggler" type="button" aria-controls="subnavigation" aria-expanded={isMenuOpen ? 'true' : 'false'} aria-label="Toggle navigation" ref={setMainMenuBtnRef} onClick={toggleMenu}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" stroke="none" className="icon" fill="#fff"><path stroke="none" d="M0 2.1h50v8.3H0zm0 18.7h50v8.3H0zm0 18.8h50v8.3H0z" className="st0"></path></svg>
                </button>
                <div className={"navbar-collapse " + (isMenuOpen ? ' show' : 'collapse')} id="subnavigation" ref={setMainMenuRef}>
                    <ul className="navbar-nav">
                        {/* {isAuthenticated && props.userData && (props.userData.isCompanyAdmin || props.userData.isUpmAdmin) &&
                            <li className="an-primary-links__list-item">
                                <Link className="an-primary-links__link" to="/userslist">{t('header.manageUsers')}</Link>
                            </li>
                        }
                        {isAuthenticated && props.userData &&
                            <li className="an-primary-links__list-item">
                                <Link className="an-primary-links__link" to="/help">{t('header.help')}</Link>
                            </li>
                        } */}
                    </ul>
                </div>

                {isAuthenticated && props.userData && (props.userData.isCompanyAdmin || props.userData.isUpmAdmin) &&
                    <div className="company-application">
                        <Companyselector onError={props.onError} userData={props.userData} handleCompanyChange={props.onCompanyChange} />
                        <Applicationselector onError={props.onError} userData={props.userData} selectedCompany={props.selectedCompany} selectedApplication={props.selectedApplication} handleApplicationChange={props.onApplicationChange} />
                    </div>
                }

                <button className="navbar-toggler" aria-controls="usersubnavigation" aria-expanded={isUserMenuOpen ? 'true' : 'false'} aria-label="Toggle user navigation" ref={setUserMenuBtnRef} onClick={toggleUserMenu}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" height="50px" width="50px" fill="#fff" stroke="none"
                        className="">
                        <path stroke="none"
                            d="M25 50C11.2 50 0 38.8 0 25S11.2 0 25 0s25 11.2 25 25-11.2 25-25 25zm0-45.3C13.8 4.7 4.7 13.8 4.7 25S13.8 45.3 25 45.3 45.3 36.2 45.3 25 36.2 4.7 25 4.7z"
                            className="st0"></path>
                        <path stroke="none"
                            d="M28.2 23l-.5-.2-.5.3c-.8.3-1.5.7-2.3.7s-1.7-.2-2.5-.7l-.5-.3-.6.2c-3.9 1.7-6.4 5.7-6.4 10.4v7.4l2.5 1.2v-8.6c0-3.4 1.7-6.5 4.4-7.9.5.2 1.2.3 1.8.5.8.2 1.8.2 2.7 0 .5-.2 1-.3 1.5-.5 2.7 1.3 4.4 4.5 4.4 7.9V42l2.5-1.2v-7.4c-.1-4.5-2.7-8.7-6.5-10.4zM24.7 8.4c-1.8 0-3.7.7-5 2-1.3 1.3-2 3-2 5 0 3.9 3.2 6.9 7 6.9 1.8 0 3.7-.7 5-2 1.3-1.3 2-3 2-5 0-3.9-3.2-6.9-7-6.9zm4.5 6.9c0 1.2-.5 2.3-1.3 3.2s-2 1.3-3.4 1.3c-2.5 0-4.5-2-4.5-4.5 0-1.2.5-2.3 1.3-3.2s2-1.3 3.4-1.3c2.5.1 4.5 2.1 4.5 4.5z"
                            className="st0"></path>
                    </svg>
                </button>
                <ul className={"an-primary-links__list navbar-collapse " + (isUserMenuOpen ? ' show' : 'collapse')} id="usersubnavigation" ref={setUserMenuRef}>
                    <li className="an-primary-links__list-item an-primary-links__list-item--home contact-info-link">
                        {isAuthenticated &&
                            <a href="#" className="an-primary-links__link">
                                <span>{t('header.welcome') + user.name}</span>
                                <img title={t('header.logOut')} style={{ cursor: "pointer" }} className="login-logout-button" onClick={() => { sessionStorage.removeItem('authenticated'); logout(); }} className="loginImage" src={logoutIcon}></img>
                            </a>}
                        {!isAuthenticated &&
                            <a title={t('header.logIn')} style={{ cursor: "pointer" }} href="#" onClick={() => login(loginRequest, isMobileAccess() ? "loginRedirect" : "loginPopup")} className="an-primary-links__link">{t('header.logIn')}
                                <img className="loginImage" src={loginIcon}></img>
                            </a>}
                    </li>
                </ul>
            </nav>
        </header>
    );
};

export default Menu;