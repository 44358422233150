import React, { useState, useEffect, useContext } from "react";
import * as msal from "@azure/msal-browser"
import * as Constants from './constants/globalVars.js'

const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const msedge = ua.indexOf("Edge/");
const msedgeOld = ua.indexOf("Edg/");
const isIE = msie > 0 || msie11 > 0;
const isEdge = false; // msedge > 0 || msedgeOld > 0;

export const MsalContext = React.createContext();
export const useMsal = () => useContext(MsalContext);
export const MsalProvider = ({
    children,
    config
}) => {
    const [isAuthenticated, setIsAuthenticated] = useState();
    const [user, setUser] = useState();
    const [token, setToken] = useState();
    const [publicClient, setPublicClient] = useState();
    const [loading, setLoading] = useState(false);
    const [popupOpen, setPopupOpen] = useState(false);
    const [loginError, setLoginError] = useState(false);

    useEffect(() => {
        const pc = new msal.PublicClientApplication(config);
        setPublicClient(pc);

        pc.handleRedirectPromise().then((response) => {
            setLoading(false);
            if (response && response.accessToken) {
                setUser(pc.getAllAccounts()[0]);
                setIsAuthenticated(true);
                setToken(response.accessToken);

                sessionStorage.setItem('authenticated', 'true');

            }
        }).catch(error => {
            console.log(error);
            setLoginError(error);
        });

        if (pc.getAllAccounts() && pc.getAllAccounts().length) {
            setUser(pc.getAllAccounts()[0]);
            setIsAuthenticated(true);
            setToken(getCachedTokenFromSessionStorage())

            sessionStorage.setItem('authenticated', 'true');
        }
    }, []);

    const login = async (loginRequest, method) => {
        sessionStorage.setItem('loginTriggered', 'true');

        const signInType = (isIE || isEdge) ? "loginRedirect" : method;
        if (signInType === "loginPopup") {
            setPopupOpen(true);

            try {
                await publicClient.loginPopup(loginRequest);

                if (publicClient.getAllAccounts()[0]) {
                    setUser(publicClient.getAllAccounts()[0]);
                    setIsAuthenticated(true);

                    sessionStorage.setItem('authenticated', 'true');

                    const response = await publicClient.acquireTokenSilent({
                        account: publicClient.getAllAccounts()[0],
                        scopes: loginRequest.scopes
                    });
                    setToken(response.accessToken);
                }
            } catch (error) {
                console.log(error);
                setLoginError(error);
            } finally {
                setPopupOpen(false);
            }
        } else if (signInType === "loginRedirect") {
            setLoading(true);

            publicClient.loginRedirect(loginRequest)

            if (publicClient.getAllAccounts()[0]) {
                setUser(publicClient.getAllAccounts()[0]);
                setIsAuthenticated(true);

                sessionStorage.setItem('authenticated', 'true');

                const response = await publicClient.acquireTokenSilent({
                    account: publicClient.getAllAccounts()[0],
                    scopes: loginRequest.scopes
                });
                setToken(response.accessToken);
            }
        }
    }

    const logout = () => {
        publicClient.logout();
    }

    const getTokenPopup = async (loginRequest) => {
        try {
            const response = await publicClient.acquireTokenSilent({
                account: user,
                scopes: loginRequest.scopes
            });
            setToken(response.accessToken);
            return response.accessToken;
        } catch (error) {
            console.log(error)
            try {
                setPopupOpen(true);
                const response = await publicClient.acquireTokenPopup(loginRequest);
                setToken(response.accessToken);
            }
            catch (error) {
                console.log(error);
                setLoginError(error);
            }
            finally {
                setPopupOpen(false);
            }
        }
    }

    // This function can be removed if you do not need to support IE
    const getTokenRedirect = async (loginRequest) => {
        try {
            const response = await publicClient.acquireTokenSilent({
                account: user,
                scopes: loginRequest.scopes
            });

            setToken(response.accessToken);
            return response.accessToken;
        }
        catch (error) {

            try {
                setLoading(true);

                publicClient.acquireTokenRedirect({
                    account: user,
                    scopes: loginRequest.scopes
                });
            }
            catch (error) {
                console.log(error);
                setLoginError(error);
            }
        }
    }

    const getToken = async (loginRequest, method) => {
        const signInType = (isIE || isEdge) ? "loginRedirect" : method;
        if (signInType === "loginRedirect") {
            return await getTokenRedirect(loginRequest);
        } else {
            return await getTokenPopup(loginRequest);
        }
    }

    //obtaining token from localStorage by scope name valid for API
    const getCachedTokenFromLocalStorage = () => {
        let token = '';
        let tokenFromStorage = Object.keys(localStorage).filter((key) => key.indexOf('testscope') > 0);

        if (tokenFromStorage && tokenFromStorage.length) {
            token = JSON.parse(tokenFromStorage.map((key) => localStorage[key])).secret;
        }

        return token;
    }

    //obtaining token from sessionStorage by scope name valid for API
    const getCachedTokenFromSessionStorage = () => {
        let token = '';
        const isLocalDevEnv = window.location.origin && window.location.origin.indexOf(Constants.LOCAL_DEV_ENV) > -1;
        const isDevEnv = window.location.origin && (window.location.origin.indexOf(Constants.DEV_ENV) > -1 || window.location.origin.indexOf(Constants.DEV_ENV_WEBAPP) > -1);
        const isTestEnv = window.location.origin && (window.location.origin.indexOf(Constants.TEST_ENV) > -1 || window.location.origin.indexOf(Constants.TEST_ENV_STAGING) > -1);
        const isProdEnv = window.location.origin && (window.location.origin.indexOf(Constants.PROD_ENV) > -1 || window.location.origin.indexOf(Constants.PROD_ENV_STAGING) > -1);
        const claimsScope = isLocalDevEnv || isDevEnv ? Constants.DEV_SCOPE : isTestEnv ? Constants.TEST_SCOPE : isProdEnv ? Constants.PROD_SCOPE : Constants.DEV_SCOPE;
        let tokenFromStorage = Object.keys(sessionStorage).filter((key) => key.indexOf(claimsScope) > 0);

        if (tokenFromStorage && tokenFromStorage.length) {
            token = JSON.parse(tokenFromStorage.map((key) => sessionStorage[key])).secret;
        }

        return token;
    }

    return (
        <MsalContext.Provider
            value={{
                isAuthenticated,
                user,
                token,
                loading,
                popupOpen,
                loginError,
                login,
                logout,
                getToken
            }}
        >
            {children}
        </MsalContext.Provider>
    );
};