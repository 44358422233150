export const LOCAL_DEV_ENV = "localhost";

export const DEV_ENV = "dev.useradmin.upmtimber.com";

export const DEV_ENV_WEBAPP = "dev.useradmin.upmtimber.com";

export const TEST_ENV = "qa.useradmin.upmtimber.com";

export const TEST_ENV_STAGING = "app-timberwebadmintool-qa-we-001-staging.azurewebsites.net";

export const PROD_ENV = "useradmin.upmtimber.com";

export const PROD_ENV_STAGING = "app-timberwebadmintool-prod-we-001.azurewebsites.net"

export const DEV_SCOPE = ["5a47a470-322e-4ae3-af16-cb7e7ed47a4c/testscope"];

export const TEST_SCOPE = ["6767996c-4c42-4c24-9c41-e9abdd778501/testscope"];

export const PROD_SCOPE = ["09df3578-13a3-4bc3-8359-2ee6e88d47ec/production"];

export const DEV_CLIENT_ID = "5a47a470-322e-4ae3-af16-cb7e7ed47a4c";

export const TEST_CLIENT_ID = "6767996c-4c42-4c24-9c41-e9abdd778501";

export const PROD_CLIENT_ID = "09df3578-13a3-4bc3-8359-2ee6e88d47ec";

export const TENANT_ID = "9eab37f0-91c6-47e3-9c00-fe8544bd272e";

export const DEV_AI_INSTR_KEY = "ea7eec64-e230-422b-9ae2-1c83453e7538";

export const TEST_AI_INSTR_KEY = "0f494cfd-663b-4d0b-a0c0-baddecc53426";

export const PROD_AI_INSTR_KEY = "910e9131-87c3-4758-b981-4f7e6f9a4a77";