import * as Constants from './../constants/globalVars.js'

//const ua = window.navigator.userAgent;
const isLocalDevEnv = window.location.origin && window.location.origin.indexOf(Constants.LOCAL_DEV_ENV) > -1;
const isDevEnv = window.location.origin && (window.location.origin.indexOf(Constants.DEV_ENV) > -1 || window.location.origin.indexOf(Constants.DEV_ENV_WEBAPP) > -1);
const isTestEnv = window.location.origin && (window.location.origin.indexOf(Constants.TEST_ENV) > -1 || window.location.origin.indexOf(Constants.TEST_ENV_STAGING) > -1);
//const isProdEnv = window.location.origin && (window.location.origin.indexOf(Constants.PROD_ENV) > -1 || window.location.origin.indexOf(Constants.PROD_ENV_STAGING) > -1);
let claimsScope, clientId;

if (isLocalDevEnv || isDevEnv) {
    // console.log('dev');
    claimsScope = Constants.DEV_SCOPE;
    clientId = Constants.DEV_CLIENT_ID;
}
else if (isTestEnv) {
    // console.log('test');
    claimsScope = Constants.TEST_SCOPE;
    clientId = Constants.TEST_CLIENT_ID;
}
else {
    // console.log('prod');
    claimsScope = Constants.PROD_SCOPE;
    clientId = Constants.PROD_CLIENT_ID;
}

export const msalConfig = {
    auth: {
        authority: 'https://login.microsoftonline.com/' + Constants.TENANT_ID,
        clientId: clientId,
        redirectUri: window.location.origin
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: false
    }
};


export const loginRequest = {
    scopes: claimsScope,
    forceRefresh: false
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const apiRequest = {
    scopes: claimsScope,
    forceRefresh: false
};

export const getEnvironment = () => {
    if (isDevEnv || isLocalDevEnv){
        return 'DEV';
    }
    if (isTestEnv){
        return 'Q & A';
    }  
    return '';
};

